import React from 'react';
import { connect } from 'react-redux';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { wrap, mainContent } from '../components/styles';
import { AppState } from '../redux-modules/root';

import { ContainerProps } from './Container';
import * as client from '../redux-modules/client';
import * as dynamicclient from '../redux-modules/dynamicclient';
import * as Types from '../api/definitions';

import { domainPath } from '../App';
import DynamicClientDetails from '../components/DynamicClientDetails';
import { KeyUpService } from '../Services/KeyUpService';

import { getModifyHardSave, downloadKidReport } from '../api/api';
interface MatchParams {
    index: string;
}
export interface DynamicClientDetailsContainerState {
    isLoading: boolean;
    error: string;
    hasError: boolean;
    program_completion_response: string | null;
}
export interface DynamicClientDetailsContainerProps extends ContainerProps<MatchParams>, WithSnackbarProps {
    updateProgramCompletion2: (client: Types.DynamicClient, client_code: string, Config: string, subConfig: string) => Promise<string>;
    submitPrediction: (client: Types.Client) => Promise<void>;
    getLocations: (client_code: string, selected_program: string, is_accessToken: any) => Promise<void>;
    getPcr: (client_code: string, selected_program: string) => Promise<void>;
    getPredictions: (
        client_code: string,
        Config: string, 
        subConfig: string,
        selected_program: string,
        selected_name: string,
        is_prediction_screen: boolean,
        isPredictionScores: boolean
    ) => Promise<void>;
    getProgramAndLikelihood: (client_code: string, selected_program: string, Config: string, subConfig: string) => Promise<void>;
    DsaveLocationAndProgram: (
        selected_location: string,
        selected_program: string,
        client_code: string
    ) => Promise<void>;
    clearErrors: () => void;
    clearClient: () => void;
    getProgramsForClient: (client_code: string, version: string) => Promise<void>;
    updateFormValues: (client_code: string, values: any) => void;
    // getReferral: () => Promise<void>;
    Referral: Types.Referral[];
    getSearchFields: ( config: string, subConfig: string, is_accessToken: string) => void;
    dynamicSearchClient: (data: any, is_accessToken: string, Config: string, subConfig: string, version: number, keySearch: any) => void;
}

export class DynamicClientDetailsContainer extends React.Component<
    DynamicClientDetailsContainerProps,
    DynamicClientDetailsContainerState
> {
    constructor(props: DynamicClientDetailsContainerProps) {
        super(props);
        this.state = this.getInitialState();
    }
    getInitialState() {
        return {
            isLoading: false,
            hasError: false,
            error: '',
            program_completion_response: null,
        }
    }

    componentDidMount() {
        this.loadClientDetails();
    }

    loadClientDetails = async () => {
       const locationVersionComplete =
            this.props && this.props.location && (this.props.location.state as Types.CustomizedHistoryState);
        const locationVersion: string = locationVersionComplete?.version;

        const versionIndex: any =
            locationVersion &&
            (parseInt(locationVersion?.substring(1)) > 0
                ? parseInt(locationVersion?.substring(1)) - 1
                : parseInt(locationVersion?.substring(1)));

        const { client: clientState, dynamicclient: questionsState } = this.props;
        const clientList = (clientState && clientState.clientList) || {};
        const { index } = KeyUpService.getConfSubConf(this.props.location.pathname);
        this.setState({ isLoading: true });
        const is_accessToken: string | any = this.props.user && this.props.user.user.accessToken;

        try {
            const {Config, subConfig} = KeyUpService.getConfSubConf(this.props.location.pathname);
           let searchFields: any = await this.props.getSearchFields(Config, subConfig, is_accessToken);

            let version = '';
            if (clientList[index] || index) {
                if (searchFields?.length > 0) {
                    let uniqueField: any = (questionsState && questionsState.uniqueIdField) || [];
                    let data: any = { [uniqueField]: index };
                    await this.props.dynamicSearchClient(data, is_accessToken, Config, subConfig, 1 , uniqueField);
                } 
            }
            // fetch program for this client
            //let isExclusiveOrg = ['perseus-house', 'mhy', 'mhy-family-services'].includes(domainPath);
            let isExclusiveOrg = ['demo'].includes(domainPath);
            if (isExclusiveOrg) {
                await this.props.getProgramsForClient(index, version);
            }
            this.setState({ isLoading: false });
            this.props.closeSnackbar();
            // this.props.getAvailablePrograms();
            // this.props.getReferral();
        } catch (errors) {
            this.setState({ isLoading: false });
            this.props.enqueueSnackbar('An error occured. Please try again.');
        }
    };
    isLastVersion = async (client_code: string, version: number) => {
        const is_accessToken: string | any = this.props.user && this.props.user.user.accessToken;
        const { dynamicclient: questionsState } = this.props;
        const {Config, subConfig} = KeyUpService.getConfSubConf(this.props.location.pathname);
        let searchFields: any = await this.props.getSearchFields(Config ,subConfig, is_accessToken);
        if (client_code) {
            this.props.history.push({ state: { version: undefined } });
            if (searchFields?.length > 0) {
                let uniqueField: any = (questionsState && questionsState.uniqueIdField) || [];
                    let data: any = { [uniqueField]: client_code };
                await this.props.dynamicSearchClient(data, is_accessToken, Config ,subConfig, version, uniqueField);
            } 
        }
    };
    
    updateProgramCompletion2 = async (clientForm: Types.DynamicClient) => {
        try {
            this.setState({ isLoading: true });
            const { Config, subConfig, index } = KeyUpService.getConfSubConf(this.props.location.pathname);
            await this.props.updateProgramCompletion2(clientForm, index, Config, subConfig);
            this.loadClientDetails();
            this.setState({
                isLoading: false
                // program_completion_response: response
            });
            this.props.enqueueSnackbar('Data saved successfully.');
        } catch (error) {
            console.log(error);
            this.setState({
                isLoading: false
                // program_completion_response: "An error occured. Please try again."
            });
            this.props.enqueueSnackbar(`${error}`);
        }
    };

    getLocationsAndPcr = async (client_code: string, selected_program: string, values: any) => {
        this.setState({ isLoading: true });
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
        await this.props.getPcr(client_code, selected_program);
        await this.props.getLocations(client_code, selected_program, is_accessToken);
        this.props.updateFormValues(client_code, values);
        this.setState({ isLoading: false });
    };

    getProgramAndLikelihoodScore = async (
        selected_program: string,
        selected_name: string,
        callback: (result) => void
    ) => {
        this.setState({ isLoading: true });
        const { Config, subConfig, index } = KeyUpService.getConfSubConf(this.props.location.pathname);
        let result =
            domainPath === 'county-demo'
                ? await this.props.getProgramAndLikelihood(index, selected_program, Config, subConfig)
                : await this.props.getPredictions(index, Config, subConfig, selected_program, selected_name, false, false);
        callback(result);
        this.setState({ isLoading: false });
        return result;
    };
    getVersionDetails = async (client_code: string, version: string) => {
        this.setState({ isLoading: true });
        await this.props.getProgramsForClient(client_code, version);
        this.setState({ isLoading: false });
    };
    
    downloadModifyHardSave = async (SelectedVersion) => {
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
        const {Config, subConfig, index} = KeyUpService.getConfSubConf(this.props.location.pathname);
        try {
            const response = await getModifyHardSave(is_accessToken, SelectedVersion, Config, subConfig,);
            this.props.enqueueSnackbar(response.message);
        } catch (error) {
            let errorMessage: string = error.message ? error.message : 'An error occurred while saving.';
            this.props.enqueueSnackbar(errorMessage);
        }
    };

    downloadReport = (clientCode) => {
        const {Config, subConfig} = KeyUpService.getConfSubConf(this.props.location.pathname);
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
        downloadKidReport(clientCode,is_accessToken, Config, subConfig, false, (result: any) => {
            
            if(result.status === "failed"){
               this.props.enqueueSnackbar(result.message);
            }
         
        })
        
    }

    RedirectForReRefer = () => {
        const {Config, subConfig, index} = KeyUpService.getConfSubConf(this.props.location.pathname);
        this.props.history.push({
            pathname: `/${domainPath}/existing-client/edit-details/config=${Config}&subConfig=${subConfig}&index=${index}&isEdit=true&Rerefer=true`,
            state: {
                isPredictionScores: false
            }
        });
    };

    render() {
        const { client: clientState, referral: referralState, dynamicclient: questionsState } = this.props;
        const referralList = (referralState && referralState.referralList) || [];
        const clientList = (clientState && clientState.clientList) || {};
        let uniqueIdField: any = (questionsState && questionsState.uniqueIdField) || [];
        const { index, Config, subConfig } = KeyUpService.getConfSubConf(this.props.location.pathname);
        const { is_prediction_available }: any = this.props.user && this.props.user.user;
        const { is_kid_report_configured }: any = this.props.user && this.props.user.user;
        const is_role_type: any = this.props.user && this.props.user.user.role_type;
        const header_color: any = this.props.user && this.props.user.user.header_color;
        const searchData: any =
            this.props && this.props.client && this.props.client.searchData && this.props.client.searchData;
        const org_type: any = this.props && this.props.user && this.props.user.user && this.props.user.user.org_type;
        const is_accessToken: string | null = (this.props.user && this.props.user.user.accessToken) || null;
        //Find Version : 1. Open through Notifications -> Notifications version,
        //Find Version : 2. Open through Search Client -> Last Version
        // const locationVersion =
        //     this.props && this.props.location && this.props.location.state && this.props.location.state.version;
        const locationVersionComplete =
            this.props && this.props.location && (this.props.location.state as Types.CustomizedHistoryState);
        const locationVersion: string = locationVersionComplete?.version;
        //Finding last version for specific client - while searing through client search screen
        const alwaysLastVersion =
            searchData &&
            searchData
                ?.filter(
                    (section) =>
                        section[`${uniqueIdField}`]?.toString() === index 
                )
                ?.pop()
                ?.version?.substring(1) - 1;
        const versionIndex = locationVersion
            ? parseInt(locationVersion?.substring(1)) > 0
                ? parseInt(locationVersion?.substring(1)) - 1
                : parseInt(locationVersion?.substring(1))
            : alwaysLastVersion;
            const refferalInfo: any = this.props.user && this.props.user.user.referral_info;

            return (
                <div css={wrap}>
                    <div css={mainContent}>
                        {!clientList[index] ? null : (
                            <DynamicClientDetails
                                onVersionSelect={this.getVersionDetails}
                                searchData={searchData.filter((s) => s[uniqueIdField].toString() === index)}
                                onProgramSelect={this.getProgramAndLikelihoodScore}
                                is_role_type={is_role_type}
                                is_accessToken={is_accessToken}
                                is_prediction_available={is_prediction_available}
                                is_kid_report_configured={is_kid_report_configured}
                                client={clientList[index]}
                                index={index}
                                DynamicQuestions={clientList[index].sections.filter(
                                    (sec) => sec.section === 'Outcomes'
                                )}
                                {...this.state}
                                org_type={org_type}
                                versionIndex={versionIndex}
                                isLastVersion={this.isLastVersion}
                                Referral={referralList}
                                onFormSubmit={this.updateProgramCompletion2}
                                program_completion_response={this.state.program_completion_response}
                                downloadModifyHardSave={this.downloadModifyHardSave}
                                header_color={header_color}
                                RedirectForReRefer={this.RedirectForReRefer}
                                uniqueIdField={uniqueIdField}
                                configType= {Config}
                                subConfigType={subConfig}
                                downloadKidReport={this.downloadReport}
                                refferalInfo={refferalInfo}
                            />
                        )}
                    </div>
                </div>
            );
        }
    }


const mapStateToProps = (state: AppState) => {
    return {
        client: state.client,
        program: state.program,
        referral: state.referral,
        user: state.user,
        dynamicclient: state.dynamicclient
    };
};

const mapDispatchToProps = {
    updateProgramCompletion2: dynamicclient.actions.updateProgramCompletion2,
    submitPrediction: client.actions.submitPrediction,
    getLocations: dynamicclient.actions.getLocations,
    getPcr: dynamicclient.actions.getPcr,
    getPredictions: dynamicclient.actions.getPredictions,
    getProgramAndLikelihood: dynamicclient.actions.getProgramAndLikelihood,
    saveLocationAndProgram: dynamicclient.actions.saveLocationAndProgram,
    clearErrors: client.actions.clearErrors,
    clearClient: client.actions.clear,
    getProgramsForClient: dynamicclient.actions.getProgramsForClient,
    updateFormValues: dynamicclient.actions.updateFormValues,
    getSearchFields: dynamicclient.actions.getSearchFields,
    dynamicSearchClient: dynamicclient.actions.dynamicSearchClient
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(DynamicClientDetailsContainer));