// Model definitions
export interface User {
    email: string;
    accessToken: string;
    role_type: string;
    user_id: string;

    name: string;
    referral_info: any;
    org_type: string;
    is_pwd_updated: string;
    is_pwd_expired: string;
    pwd_expires_in: string;
    logo_path: string;
    header_color: string;
    is_fully_configured: string;
    performance_entry: string;
    is_prediction_available: string;
    is_kid_report_configured: string;
    count_down: number;

    is_dashboard_published: string;
    billing_status: boolean;

}
export interface Users {
    id: string;
    name: string;
    full_name: string;
    first_name: string;
    last_name: string;
    email_id: string;
    mobile: string;
    gender: string;
    role_type: string;
}
export interface CustomizedHistoryState {
    version: string;
}
export interface Billing {
    id: string;
    customer: string;
    startDate: string;
    endDate: string;
    sDate: string;
    eDate: string;
}
export interface Notifications {
    startDate: string;
    endDate: string;
    sDate: string;
    eDate: string;
}

export interface NotificationsList {
    url:string;
    id:number;
    name:string;
    customer:number;
    is_published:string
}
export interface Credential {
    email: string;
    password: string;
    domain: string;
}
export interface ChangePwd {
    old_password: string;
    password: string;
    retype_password: string;
}
export interface DynamicClient {
    [key: string]: any;
}
export interface DynamicFamily {
    [key: string]: any;
}
export interface Client { 
    Referral_Status?: any;
    client_code: string | null;
    _id: any;
    Program: string | null;
    id_n?: any;
    referral_status: string | null;
    Reason_for_rejected: string | null;
    Reason_not_accepted: string | null;
    episode_start: string | null;
    episode_number: string | null;
    name: string | null;
    last_name: string | null;
    dob: string | null;
    age: string | null;
    gender: string | number | null;
    primary_language: string | null;
    RefSourceCode: string | null;
    ls_type: string | null;
    CYF_code: string | null;
    number_of_prior_placements: string | null;
    number_of_foster_care_placements: string | null;
    number_of_prior_AWOLS: string | null;
    number_of_prior_treatment_terminations: string | null;
    termination_directly_to_AV: string | null;
    length_of_time_since_living_at_home: string | null;
    hist_of_prior_program_SAO: string | number | null;
    autism_Diagnosis: string | number | null;
    borderline_Personality: string | number | null;
    reactive_Attachment_Disorder: string | number | null;
    animal_cruelty: string | number | null;
    schizophrenia: string | number | null;
    psychosis: string | number | null;
    borderline_IQ: string | null;
    significant_mental_health_symptoms: string | null;
    prior_hospitalizations: string | null;
    severe_mental_health_symptoms: string | null;
    compliant_with_meds: string | null;
    Exclusionary_Criteria: boolean;
    incarcerated_caregivers: string | number | null;
    death_Caregiver: string | number | null;
    incarcerated_siblings: string | number | null;
    death_Silblings: string | number | null;
    alcohol_Use: string | number | null;
    drug_Use: string | number | null;
    abuse_neglect: string | number | null;
    yls_FamCircumstances_Score: number | null;
    yls_Edu_Employ_Score: string | null;
    yls_Peer_Score: string | null;
    yls_Subab_Score: string | null;
    yls_Leisure_Score: string | null;
    yls_Personality_Score: string | null;
    yls_Attitude_Score: string | null;
    yls_PriorCurrentOffenses_Score: string | null;
    family_support: string | null;
    fire_setting: string | null;
    level_of_aggression: string | null;
    client_self_harm: string | null;
    Screening_tool_Trauma: string | null;
    cans_LifeFunctioning: string | null;
    cans_YouthStrengths: string | null;
    cans_CareGiverStrengths: string | null;
    cans_Culture: string | null;
    cans_YouthBehavior: string | null;
    cans_YouthRisk: string | null;
    cans_Trauma_Exp: string | null;
    primaryRaceCode: string | null;
    ageAtEpisodeStart: string | null;
    ageAtEnrollStart: string | null;
    enrollStart_date: string | null;
    start_date: string | null;
    end_date: string | null;
    english_second_lang: string | null;
    type_of_drugs: string | null;
    FAST_FamilyTogetherScore: string | null;
    FAST_CaregiverAdvocacyScore: string | null;
    Confidence: number | null;
    confidence: number | null;
    Roc_confidence: number | null;
    roc_confidence: number | null;
    supervision_level: number | null;
    Likelihood_to_Recidivate1: number | null;
    Likelihood_to_Recidivate2: number | null;
    referral_type: string | null;
    Level_of_care: string | null;
    program_type: string | null;
    referred_program: string | null;
    model_program: string | null;
    client_selected_program: string | null;
    client_selected_locations: string | null;
    SuggestedPrograms: string[] | null;
    SuggestedReferral: string[] | null;
    program_model_suggested: string[] | null;
    selected_program: string | null;
    selected_location: string | null;
    selected_referral: string | null;
    SuggestedLocations: string[] | null;
    result_final: string | null;
    inclusionary_criteria: boolean;
    Program_Completion: number | null;
    Returned_to_Care: number | null;
    Remained_Out_of_Care: number | null;
    referral: string | null;
    program_significantly_modified: number | null;
    client_psychiatrically_hospitalized: number | null;
    sections: any;
    length_of_stay: string | null;
    discharge_location: string | null;
    client_recidivate: string | null;
    client_selected_confidence: number | null;
    client_selected_roc_confidence: number | null;
    SuggestedFamilies: string[] | null;
    MappedFamiliesToYouth: any;
    super_vision_list: any;
}
export interface Referral {
    referral_code: number;
    referral_name: string;
}

export interface Program {
    program: number;
    program_name: string;
}

export interface Location {
    location: number;
    location_names: string;
}

export interface Configuration {
    gender: number | null;
    program: number | null;
    referral: number | null;
    level_of_care: number | null;
    location: number[] | null;
    facility_type: number | null;
    program_model_suggested: string;
    program_type: string;
}

export interface Prediction {
    Program: string;
    Confidence: number;
    Level_of_care: string;
}

export interface ResultPrograms {
    Program: string;
    Confidence: number;
    Level_of_care: string;
}

export interface SuggestedLocations {
    SuggestedLocations: [string];
}

interface ObjectLiteral {
    [key: string]: any;
}
export const emptyDClient: DynamicClient = {};
export const emptyDFamily: DynamicFamily = {};
export const emptyClient: Client = {
    client_code: null,
    _id: null,
    Program: null,
    referral_status: null,
    Reason_for_rejected: null,
    Reason_not_accepted: null,
    sections: null,
    referral: null,
    episode_start: null,
    episode_number: null,
    name: null,
    last_name: null,
    dob: null,
    age: null,
    gender: null,
    primary_language: null,
    RefSourceCode: null,
    ls_type: null,
    CYF_code: null,
    number_of_prior_placements: null,
    number_of_foster_care_placements: null,
    number_of_prior_AWOLS: null,
    number_of_prior_treatment_terminations: null,
    termination_directly_to_AV: null,
    length_of_time_since_living_at_home: null,
    hist_of_prior_program_SAO: null,
    autism_Diagnosis: null,
    borderline_Personality: null,
    reactive_Attachment_Disorder: null,
    animal_cruelty: null,
    schizophrenia: null,
    psychosis: null,
    borderline_IQ: null,
    significant_mental_health_symptoms: null,
    prior_hospitalizations: null,
    severe_mental_health_symptoms: null,
    compliant_with_meds: null,
    Exclusionary_Criteria: false,
    incarcerated_caregivers: null,
    death_Caregiver: null,
    incarcerated_siblings: null,
    death_Silblings: null,
    alcohol_Use: null,
    drug_Use: null,
    abuse_neglect: null,
    yls_FamCircumstances_Score: null,
    yls_Edu_Employ_Score: null,
    yls_Peer_Score: null,
    yls_Subab_Score: null,
    yls_Leisure_Score: null,
    yls_Personality_Score: null,
    yls_Attitude_Score: null,
    yls_PriorCurrentOffenses_Score: null,
    family_support: null,
    fire_setting: null,
    level_of_aggression: null,
    client_self_harm: null,
    Screening_tool_Trauma: null,
    cans_LifeFunctioning: null,
    cans_YouthStrengths: null,
    cans_CareGiverStrengths: null,
    cans_Culture: null,
    cans_YouthBehavior: null,
    cans_YouthRisk: null,
    cans_Trauma_Exp: null,
    primaryRaceCode: null,
    ageAtEpisodeStart: null,
    ageAtEnrollStart: null,
    enrollStart_date: null,
    start_date: null,
    end_date: null,
    english_second_lang: null,
    type_of_drugs: null,
    FAST_FamilyTogetherScore: null,
    FAST_CaregiverAdvocacyScore: null,
    referred_program: null,
    client_selected_program: null,
    client_selected_locations: null,
    program_type: null,
    Confidence: null,
    confidence: null,
    roc_confidence: null,
    Roc_confidence: null,
    supervision_level: null,
    Likelihood_to_Recidivate1: null,
    Likelihood_to_Recidivate2: null,
    referral_type: null,
    Level_of_care: null,
    SuggestedLocations: null,
    SuggestedPrograms: null,
    SuggestedReferral: null,
    model_program: null,
    selected_program: null,
    selected_location: null,
    selected_referral: null,
    result_final: null,
    inclusionary_criteria: false,
    Program_Completion: null,
    Returned_to_Care: null,
    Remained_Out_of_Care: null,
    program_significantly_modified: null,
    client_psychiatrically_hospitalized: null,
    program_model_suggested: null,
    length_of_stay: null,
    discharge_location: null,
    client_recidivate: null,
    client_selected_confidence: null,
    client_selected_roc_confidence: null,
    Referral_Status: undefined,
    SuggestedFamilies: null,
    MappedFamiliesToYouth: null,
    super_vision_list: null
};

export const emptyConfiguration: Configuration = {
    gender: null,
    program: null,
    referral: null,
    level_of_care: null,
    location: [],
    facility_type: null,
    program_model_suggested: '',
    program_type: ''
};

export const emptyBilling: Billing = {
    id: '',
    customer: '',
    startDate: '',
    endDate: '',
    sDate: '',
    eDate: ''
};

export const emptyNotifications: Notifications = {
    startDate: '',
    endDate: '',
    sDate: '',
    eDate: ''
};
export const primary_language: string[] = ['', 'English', 'Other'];

export const RefSourceCode: ObjectLiteral = {
    '1': 'Adams',
    '2': 'Allegheny',
    '3': 'Beaver',
    '4': 'Bedford',
    '5': 'Berks',
    '6': 'Blair',
    '7': 'Bucks',
    '8': 'Butler',
    '9': 'Cambria',
    '10': 'Centre',
    '11': 'Chester',
    '12': 'Cumberland',
    '13': 'Dauphin',
    '14': 'Delaware',
    '15': 'Erie',
    '16': 'Fayette',
    '17': 'Franklin',
    '18': 'Huntingdon',
    '19': 'Juniata',
    '20': 'Kent',
    '21': 'Lackawanna',
    '22': 'Lancaster',
    '23': 'Lebanon',
    '24': 'Lehigh',
    '25': 'Lycoming',
    '26': 'Monroe',
    '27': 'Montgomery',
    '28': 'Montour',
    '29': 'Northumberland',
    '30': 'Perry',
    '31': 'Philadelphia',
    '32': 'Pike',
    '34': 'Snyder',
    '35': 'Tioga',
    '36': 'Washington',
    '37': 'Westmoreland',
    '38': 'York',
    '39': 'Armstrong',
    '40': 'Columbia',
    '41': 'Crawford',
    '42': 'Cayahoga OH',
    '43': 'Franklin OH',
    '44': 'Greene',
    '45': 'Indiana',
    '46': 'Lawrence',
    '47': 'MH',
    '48': 'Mckean',
    '49': 'Mercer',
    '50': 'outside tri-county',
    '51': 'Northampton',
    '52': 'Pike',
    '53': 'Schukill',
    '54': 'Somerset',
    '55': 'Union',
    '56': 'Venango',
    '57': 'Fulton',
    '59': 'WV',
    '60': 'SE PA'
};

export const ls_type: ObjectLiteral = {
    '1': 'Voluntary',
    '2': 'Dependant',
    '3': 'Voluntary Delinquent',
    '4': 'Dependant Delinquent',
    '5': 'Delinquent'
};

export const number_of_prior_placements: ObjectLiteral = {
    '0': 'None',
    '1': 'One',
    '2': 'Two',
    '3': 'Many'
};

export const number_of_foster_care_placements: ObjectLiteral = {
    '0': 'None',
    '1': 'One',
    '2': 'Two',
    '3': 'Many'
};

export const number_of_prior_AWOLS: ObjectLiteral = {
    '0': 'None',
    '1': 'One',
    '2': 'Two',
    '3': 'Many'
};

export const number_of_prior_treatment_terminations: ObjectLiteral = {
    '0': 'None',
    '1': 'One',
    '2': 'Two',
    '3': 'Many'
};

export const termination_directly_to_AV: ObjectLiteral = {
    '0': 'None',
    '1': 'Unknown',
    '2': 'One',
    '3': 'Two or more'
};

export const length_of_time_since_living_at_home: ObjectLiteral = {
    '0': '0-6 months',
    '1': '6-12 months',
    '2': '12+ months'
};

export const gender: ObjectLiteral = {
    '1': 'Female',
    '2': 'Male'
};

export const radioValues = ['No', 'Yes', '', '', '', '', '', '', '', 'N/A'];

export const borderline_IQ = ['70+', '<70'];

export const episode_number: ObjectLiteral = {
    '1': 'No - Episode 1',
    '2': 'Yes - Episode 2',
    '3': 'Yes - Episode 3',
    '4': 'Yes - Episode 4',
    '5': 'Yes - Episode 5',
    '6': 'Yes - More than 5 episodes'
};

export const severe_mental_health_symptoms: ObjectLiteral = {
    '0': 'No ER/hospitalizations',
    '1': 'last 3 months',
    '2': '6 months ago',
    '3': '9 months ago',
    '4': '1 year or more ago'
};

export const CYF_code: ObjectLiteral = {
    '0': 'None',
    '1': 'CYF',
    '2': 'Juvenile Justice'
};

export const primaryRaceCode: ObjectLiteral = {
    '2': 'African American',
    '1': 'Caucasian',
    '3': 'Hispanic',
    '4': 'Other'
};
