/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { domainPath } from '../../App';
import { modifyOutcomeButton } from '../styles';
const ModifyHardSaveData = ({ client, is_role_type, SelectedVersion, modifyOutcome, setModifyOutcome, orgType, latestVersion, configType, subConfigType }) => {
    const [exclusionary_criteria, setExclusionary_Criteria] = useState('No');
    const [enableOutcomes, setEnableOutcomes] = useState<boolean>(false);
    const exclusionList1 = ["exclusionary", "criteria", "exists"];
    const exclusionList2 = ["referral", "rejected"];

    // Check if any word matches a list
   const matchesList = (list, words) => list.every((item) => words.includes(item.toLowerCase()))

    // Check conditions
    const checkConditions = (words) => {
        words = words.toLowerCase().split(/[\s/]+/);
        
        const matchesExclusion1 = matchesList(exclusionList1, words);
        const matchesExclusion2 = matchesList(exclusionList2, words);
        const matchesExclusion3 = matchesList(words, ['reason']);
        

        let condition1 = matchesExclusion1;
        let condition2 = matchesExclusion2 && !matchesExclusion3;
         
        if (condition1 || condition2) return true;

         return false;
    };

    useEffect(() => {
        let isCondtionSatisfied = false;
        if (SelectedVersion) {
            let isExclusionary =
                SelectedVersion &&
                SelectedVersion.sections.some((section) =>
                    section
                        ? section.questions.some((ques) => {
                              if (checkConditions(ques.question)) {
                                isCondtionSatisfied = true;
                                  setExclusionary_Criteria(ques.answer?.toString());
                                  return true; // Stop the loop
                              }
                              return false;
                          })
                        : false
                )
                
            if(!isCondtionSatisfied){
                if ([3, 4].includes(orgType)) {
                    setExclusionary_Criteria('No');
                } else {
                   setExclusionary_Criteria('Yes');
                }
            }
        }


        if (latestVersion?.version === SelectedVersion?.version) {
            setEnableOutcomes(latestVersion?.version === SelectedVersion?.version);


        } else {
            setModifyOutcome('');
            setEnableOutcomes(latestVersion?.version === SelectedVersion?.version);
        }

    }, [SelectedVersion]);

    return (
        <Fragment>
            {['Super Admin', 'Tester'].includes(is_role_type) ? (
                <Fragment>
                    Modify
                    <Link
                        to={{
                            pathname: `/${domainPath}/existing-client/edit-details/config=${configType}&subConfig=${subConfigType}&index=${client}&isEdit=true&Rerefer=false`,
                            state: {
                                isModifyVersion: SelectedVersion && SelectedVersion._id,
                                isPredictionScores: false
                            }
                        }}
                    >
                        <Button>
                            <u css={modifyOutcomeButton}>
                                <strong>Client</strong>
                            </u>
                        </Button>
                    </Link>
                    {exclusionary_criteria === 'Yes' ? (
                        ''

                    ) : (enableOutcomes) && (


                        <Fragment>
                            &nbsp;|
                            <Button
                                onClick={() => setModifyOutcome(modifyOutcome ? '' : SelectedVersion._id)}
                            >
                                <u css={modifyOutcomeButton}>
                                    <strong style={{ color: modifyOutcome ? 'blue' : 'red' }}>Outcome</strong>
                                </u>
                            </Button>
                        </Fragment>
                    )}
                </Fragment>
            ) : (
                ''
            )}
        </Fragment>
    );
};

export default ModifyHardSaveData;