/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React from 'react';
import Button from '@material-ui/core/Button';
import { domainPath } from '../App';
import { baseApiUrl } from '../api/api';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import SubHeading from './SubHeading';
import {
    wrap,
    fieldRow,
    mainContent,
    twoCol,
    selectField,
    label,
    backdrop,
    txtDetail,
    reportLink,
    panelHeader,
    panel,
    panelHeading,
    txtLabel,
    flexBetween,
    dangerLabel,
    textArea_50,
    textArea
} from './styles';
import * as Types from '../api/definitions';
import { Fragment } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Backdrop, CircularProgress } from '@material-ui/core';
import ModifyHardSaveData from './StaticOrgFeatures/ModifyHardSaveData';
import Modal from 'react-modal';
import DisplayDynamicQuestions from '../components/FamilySearchComponent/DisplayDynamicQuestions';
import * as Utility from "../Common/Base";
import { FormStateService } from '../Services/StateUpdateService';
import { QuestionJumpService } from '../Services/NestedQuestionService';
import { KeyUpService } from '../Services/KeyUpService';
import Dropdown from '../Common/DropdownComponent';
import Radio from '../Common/RadioOptionComponent';
import Checkbox from '../Common/CheckboxComponent';
import NumberField from '../Common/NumberComponent';
import DateField from '../Common/DateComponent';
import InputField from '../Common/InputComponent';

interface DynamicOutcomesProps extends RouteComponentProps  {
    Referral: Types.Referral[];
    DynamicQuestions: any;
    client: Types.Client;
    onVersionSelect: (client_code: string, version: string) => Promise<void>;
    onFormSubmit: (client: Types.Client) => void;
    isLastVersion: (id_n: string, version: number) => void;
    isLoading: boolean;
    hasError: boolean;
    error: string;
    isPredictionScores?: boolean;
    reReffer?: string;
    errors?: any;
    searchData: any;
    user?: any;
    client_id?: string;
    program_completion_response: string | null;
    is_role_type: any;
    org_type: any;
    versionIndex: number;
    is_prediction_available: any;
    is_kid_report_configured: any;
    index: any;
    downloadModifyHardSave: (SelectedVersion: string) => void;
    header_color: string;
    RedirectForReRefer: () => void;
    onProgramSelect: (program: string, name: string, callBack: (result: any) => void) => void;
    is_accessToken: string | null;
    uniqueIdField: string ;
    configType: string ;
    subConfigType:string ;
    downloadKidReport:(clientCode: string) => void;
    refferalInfo: any;
}
export interface DynamicOutcomesState  {
    uniqueIdField: string;
    isLoading: boolean;
    error: any;
    errors: any;
    hasError: boolean;
    isSubmitted: boolean;
    ClientCode: any;
    client_form: any;
    DateTypeQuestions: any;
    isValid_date: any;
    minStartDateValue: any;
    Required_List: any;
    DynamicQuestions: any;
    prevJump: any;
    prevQuestionJump: any;
    csvfile: any;
    isOpen: boolean;
    err_msg: any;
    header_color: string;
    visitedQuestion: any;
    isSuccess: boolean;
    comments: string | null;
    selectedVersion: any;
    currentVersion: string;
    outcomesRelatedQuestions: string[];
    modifyOutcome: string | null;
    version_changed: boolean;
    version_color: string;
    isModalOpen: boolean;
    isModifyData: boolean;
    subConfig:  string | null;
    configType: string | null;
}

const PendingReferralList = ['pending', 'undecided', 'admitted-pending release', ''];

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height: '30%',
        width: '50%',
        overflowY: 'scroll'
    }
};

const PredictionList = [
    'Program/Pathway Successful Completion Likelihood',
    'Likelihood to Recidivate: 1 Year',
    'Likelihood to Recidivate: 2 Years',
    'Remain Out of Residential Placement Likelihood'
];

const GetPredictionScoreList = ['Program']; 

export class DynamicClientDetails extends React.Component<DynamicOutcomesProps, DynamicOutcomesState> {
    constructor(props: DynamicOutcomesProps) {
        super(props);
        this.state = this.getInitialState();
    }
    getInitialState() {
        return {
            uniqueIdField: '',
            isLoading: false,
            ClientCode: '',
            hasError: true,
            isSubmitted: false,
            DynamicQuestions: [],
            error: [],
            errors: '',
            client_form: [],
            DateTypeQuestions: [],
            isValid_date: [],
            minStartDateValue: '',
            Required_List: [],
            prevJump: [],
            prevQuestionJump: [],
            csvfile: '',
            err_msg: [],
            isOpen: false,
            header_color: '',
            visitedQuestion: [],
            isSuccess: false,
            comments: '',
            selectedVersion: [],
            currentVersion: '',
            outcomesRelatedQuestions: [],
            modifyOutcome: '',
            version_changed: false,
            version_color: this.props?.searchData[this.props.searchData.length - 1]?.version_color,
            isModalOpen: false,
            isModifyData: false,
            subConfig:  null,
            configType: null
        };
    }

    setModifyOutcome = (value) => {
        this.setState({ modifyOutcome: value });
    };
    async componentDidMount() {
        //# Every time latest data from Redux Store (Either last Version or Notifications current version)
        let DynamicQuestions = [...this.props.DynamicQuestions];
        let DynamicQues: any = [];

        //Find all outcomes section related: 'yes' question - For ODM Features
        let outcomesRelatedQuestions = this.findAllOutcomesRelatedQuestions(DynamicQuestions[0].questions);
        //Find and store : all date type question
        let date: any[] = [];
        DynamicQuestions &&
            DynamicQuestions.map((section) => {
                section.questions &&
                    section.questions.map((ques) => {
                        if (ques.answer_type === 'DATE') {
                            date.push(ques.question.toString());
                        }
                    });
            });

        //Find and calculate :  The minimum Start Date value (preProgramYLSDate or dateOfReferral):  among both which ever is greater
        let preProgramYLSDate = '',
            dateOfReferral = '';
        this.props.client.sections.map(
            (section) =>
                section.questions &&
                section.questions.length > 0 &&
                section.questions.filter((question) => {
                    if (question.question === 'Pre-Program: YLS Date') {
                        preProgramYLSDate = question.answer;
                    }
                    if (question.question === 'Date of Referral') {
                        dateOfReferral = question.answer;
                    }
                })
        );
        let minStartDateValue: any = preProgramYLSDate > dateOfReferral ? preProgramYLSDate : dateOfReferral;

        // 1. Getting current question jumpTo value
        const currQuesJump =
            DynamicQuestions[0] &&
            DynamicQuestions[0].questions &&
            DynamicQuestions[0].questions
                .map(
                    (ques) =>
                        ques.suggested_jump.find(
                            (ans, i) =>
                                ans.answer?.toString() === ques.answer?.toString() || i.toString() === ques.answer
                        )?.question_jumpto
                )
                .filter((item) => item !== undefined)
                ?.flat();

        //2. Checking length of question jumpTo
        
        let updatedArray = this.props.searchData.length > 0 && this.props.searchData[this.props.searchData.length - 1]?.sections.map((obj, idx) => {
            return obj.questions.map((ques, idy) => {
                return this.MappedAnswersHandler(
                    '',
                    idx,
                    idy,
                    ques,
                    this.props.searchData[this.props.searchData.length - 1].sections,
                    'true'
                );
            });
           }
            
        );
        
        updatedArray = updatedArray?.flat()?.filter((item) => item && item.length !== 0 && item !== undefined);
        updatedArray = updatedArray.filter((item, index, self) =>
            index === self.findIndex((t) => t.id === item.id && t.section === item.section)
          );
          
        DynamicQuestions = updatedArray?.length === 0 ? DynamicQuestions : updatedArray;
        if (currQuesJump?.length > 0) {
            let DynamicSectionQues = [...DynamicQuestions];
            DynamicQues =
                DynamicSectionQues[0] &&
                DynamicSectionQues[0].questions &&
                DynamicSectionQues.map((sec) => {
                    sec.questions.map((ques) => {
                        if (currQuesJump?.includes(ques.question)) {
                            ques.related = 'no';
                        }
                        // else {
                        //     ques.related = 'yes';
                        // }
                        if (
                            (ques.answer && ques.answer != '') ||
                            (Array.isArray(ques.answer) && ques.answer.length > 0)
                        ) {
                            ques.related = 'no';
                        }
                        if (ques.answer_type == 'DATE') {
                            ques.answer = ques.answer.replaceAll('-', '/');
                        }
                        return ques;
                    });
                    return sec;
                });
        }
        // //# Every time latest data based on versionIndex (Either last Version or Notifications current version)
        let onSearchData = [...this.props.searchData];
        let searchData = this.props.versionIndex ? onSearchData[this.props.versionIndex] : onSearchData[0];
        // //. Finding Data for client form
        let client_data: any = {};
        searchData.sections
            .find((section) => section.section === 'Outcomes')
            ?.questions.map((ques: any) => (client_data[ques.question] = ques.answer));

        let DisableSubmit = client_data['Referral Status']
            ? !PendingReferralList.includes(client_data['Referral Status']?.toLowerCase())
                ? true
                : false
            : false;

        await this.setState({
            DynamicQuestions:
                currQuesJump?.length > 0
                    ? DynamicQues
                    : updatedArray?.length > 0
                    ? updatedArray
                    : [...this.props.DynamicQuestions],
            DateTypeQuestions: date,
            minStartDateValue: minStartDateValue,
            isOpen: this.props.errors ? true : false,
            err_msg: this.props.errors,
            header_color: this.props.header_color && this.props.header_color,
            selectedVersion: searchData && [searchData],
            currentVersion: searchData && searchData.version,
            client_form: client_data,
            outcomesRelatedQuestions: [...outcomesRelatedQuestions],
            isModifyData: DisableSubmit
        });
        this.formState();
    }

    //Finding all outcomes related : 'yes' question
    findAllOutcomesRelatedQuestions = (questions: any[]) => {
        const relatedQues: string[] = questions.filter((ques) => ques.related === 'yes').map((ques) => ques.question);
        return relatedQues;
    };

    // Find odm question from outside of outcomes section
    findOdmParentQuestion = (searchData, currentVersion) => {
        const { outcomesRelatedQuestions } = this.state;
        let odmQuestion: string[] = [];
        // Filter sections with is_odm equal to 'yes'
        searchData
            .find((version) => version.version === currentVersion)
            ?.sections.forEach((section) => {
                if (section.is_odm === 'yes') {
                    section.questions.forEach((ques) => {
                        // Filter questions with is_odm equal to 'yes'
                        if (ques.is_odm === 'yes' && ques?.suggested_jump.length > 0) {
                            ques?.suggested_jump?.forEach((ans) => {
                                if (
                                    ans.answer === (Array.isArray(ques.answer) ? ques.answer?.toString() : ques.answer)
                                ) {
                                    ans.question_jumpto.filter((j) => {
                                        if (outcomesRelatedQuestions.includes(j)) {
                                            odmQuestion.push(j);
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            });
        return odmQuestion;
    };

    // Outcome Section Question Update - Set related to 'no' for questions in DynamicQuestions that match odmQuestions
    updatedOutcomesSection = (DynamicQuestions, odmQuestion) => {
        const newDynamicQuestions = DynamicQuestions.map((sec) => {
            if (sec.related === 'false' && sec.questions) {
                const newQuestions = sec.questions.map((ques) => {
                    if (odmQuestion.includes(ques.question)) {
                        return { ...ques, related: 'no' };
                    }
                    return ques;
                });
                return { ...sec, questions: newQuestions };
            }
            return sec;
        });
        return newDynamicQuestions;
    };

    formState = async (name: string = '', value: string = '', jump: any = '', ques_jump: any = '') => {
        let DynamicQuestions = [...this.state.DynamicQuestions];
        let searchData = [...this.props.searchData];
        let { currentVersion } = this.state;
        // Find - ODM Parent Question
        const odmQuestion = await this.findOdmParentQuestion(searchData, currentVersion);

        // Update - Outcome ODM Question Related value
        const newDynamicQuestions = this.updatedOutcomesSection(DynamicQuestions, odmQuestion);
       const data =  await FormStateService.processDynamicQuestions(newDynamicQuestions,'Outcomes');
     await this.setState({
        ...data
         });
    };

    handleClose = () => {
        this.setState({
            isOpen: false,
            err_msg: [],
            isSuccess: false
        });
    };
       
    dropDownHandler = async (e, data, idx, idy, id) => {
        let DynamicQuestions = [...this.state.DynamicQuestions];
        let outcomesRelatedQuestions = [...this.state.outcomesRelatedQuestions];
        let searchData = [...this.props.searchData];
        const value = e.hasOwnProperty('value') ? e.value?.toString() : e.target.value;
        let SelectedItem = data.suggested_answers.find((item) => item.id?.toString() === value?.toString())?.value;
        const name = DynamicQuestions[idx].questions[idy].question;

        let jumpto =
            data.suggested_jump.length > 0 && value?.toString()
                ? data.suggested_jump.find((sj) => !sj.is_deleted && SelectedItem === sj.answer)?.jumpto
                : [];

        let ques_jumpto =
            data.suggested_jump.length > 0 && value?.toString()
                ? data.suggested_jump.find((sj) => !sj.is_deleted && SelectedItem === sj.answer)?.question_jumpto
                : [];
        
        DynamicQuestions[idx].questions[idy].answer = value?.toString()
            ? DynamicQuestions[idx].questions[idy].is_parent === 'yes'
                ? {
                      value: DynamicQuestions[idx].questions[idy].suggested_answers.find(
                          (item) => item.id === Number(value)
                      )?.value,
                      repeated: []
                  }
                : DynamicQuestions[idx].questions[idy].suggested_answers.find((item) => item.id === Number(value))
                      ?.value
            : [];

        //Handle question jump dynamically, recursively to the end of node
        // this.handleNestedJump(DynamicQuestions, name, value, idx);
        DynamicQuestions =  await QuestionJumpService.handleNestedJump(DynamicQuestions, idx, name, outcomesRelatedQuestions );
        if (id || id === '') {
            DynamicQuestions[idx].questions.map((que, i) => {
                if (ques_jumpto && ques_jumpto.includes(que.question)) {
                    DynamicQuestions[idx].questions[i].related = 'no';
                } else if (
                    this.state.prevQuestionJump[name?.toString()] &&
                    this.state.prevQuestionJump[name?.toString()].includes(que.question)
                ) {
                    DynamicQuestions[idx].questions[i].related = 'yes';
                    DynamicQuestions[idx].questions[i].answer = '';
                }
            });
        }

        DynamicQuestions.map((sec, i) =>
            jumpto && jumpto.includes(sec.section)
                ? (DynamicQuestions[i].related = 'false')
                : this.state.prevJump[name.replace(/ /g, '_')] &&
                  this.state.prevJump[name.replace(/ /g, '_')].includes(sec.section) &&
                  (DynamicQuestions[i].related = 'true')
        );
        this.MappedAnswersHandler(id, idx, idy, data, DynamicQuestions, 'false');
       this.setState((prevState) => ({
            ...prevState,
            DynamicQuestions,
            prevJump: {
                ...this.state.prevJump,
                [name.replace(/ /g, '_')]: jumpto,
                hasError: false
            },
            prevQuestionJump: {
                ...this.state.prevQuestionJump,
                [name.replace(/ /g, '_')]: ques_jumpto,
                hasError: false
            }
        }));
        await this.formState(name, value, jumpto, ques_jumpto);
        if (
            (domainPath === 'county-demo' && GetPredictionScoreList.includes(name)) ||
            (DynamicQuestions[idx].questions[idy]?.api_call === 'yes' &&
                DynamicQuestions[idx].questions[idy]?.for_prediction_screen === 'yes')
        ) {
            this.props.onProgramSelect(value, name, (result) => {
                if (result) {
                    
                    let client_form = this.state.client_form;
                    this.setState((prevState) => {
                        const updatedDynamicQuestions = prevState.DynamicQuestions.map((sec) => {
                            return {
                                ...sec,
                                questions: sec.questions.map((ques) => {
                                    if (PredictionList.includes(ques.question)) {
                                        ques.answer = result[`${ques.question}`];
                                        client_form[`${ques.question}`] = result[`${ques.question}`];
                                    }
                                    if (ques.for_prediction_screen === 'yes') {
                                        
                                        let convertResultToAnswer: string[] | any = [];
                                        result.length > 0 &&
                                            result.map((ques) =>
                                                convertResultToAnswer.push({ [ques.question]: ques.answer || ques.answer === 0  ? ques.answer?.toString() : ''})
                                            );
                                        convertResultToAnswer = Object.assign({}, ...convertResultToAnswer);
                                        ques.answer = convertResultToAnswer[`${ques.question}`];
                                        ques.suggested_answers = result.length > 0 ? result.find((item) => item.question === ques.question)?.suggested_answers : ques.suggested_answers;
                                        ques.suggested_jump = result.length > 0 ? result.find((item) => item.question === ques.question)?.suggested_jump : ques.suggested_jump;
                                        client_form[`${ques.question}`] = convertResultToAnswer[`${ques.question}`];
                                    }
                                    return ques;
                                })
                            };
                        },() => this.formState(name, value, jumpto, ques_jumpto));

                        DynamicQuestions = [...updatedDynamicQuestions];
                        return { DynamicQuestions: updatedDynamicQuestions, client_form };
                    });
                }
            });
        }
    };

    handleChange = async (e) => {
        
         const { name, value } = e.target;
        let DynamicQuestions = [...this.state.DynamicQuestions];
        const type = e.target.dataset.type; 
            if (type === 'file') {
                const idx = e.target.dataset.idx;
                const idy = e.target.dataset.idy;
                DynamicQuestions[idx].questions[idy].answer = value;
            }
            if (!this.state.DateTypeQuestions.includes(name)) {
                await this.setState((prevState) => ({
                    ...prevState,
                    DynamicQuestions,
                    client_form: {
                        ...this.state.client_form,
                        [name]: value
                    },
                    hasError: false
                }));
            }
    };

    checkBoxHandler = (selectedOptions, data, sectionIndex, questionIndex) => {
        
        let name = data.question;
        let selectedValues = selectedOptions.map((item) => item.value);
        const { DynamicQuestions, client_form } = this.state;
        let updatedDynamicQuestions = DynamicQuestions;
 
        const idx = sectionIndex;
            const idy = questionIndex;
            const checked = data.hasOwnProperty('answer') ? selectedValues?.length > data?.answer?.length : true ;
            updatedDynamicQuestions[idx].questions[idy].answer = selectedValues;
            this.setState((prevState) => ({
                ...prevState,
                DynamicQuestions,
                client_form: {
                    ...this.state.client_form,
                    [name]: selectedValues
                },
                hasError: false
            }),() => this.formState());
             
    }

    textFieldHandler = async (e, data) => {
        const { name, value } = e.target;
        let DynamicQuestions = [...this.state.DynamicQuestions];
        let client_form = { ...this.state.client_form };
        const val2: any = data.validation2;
        const error_msg = data.msg;
        const error_msg2 = data.errmsg;
        let parent_question= data?.parent_question;
        let is_re_type: string = data.re_type;
        let is_exact = data?.exact_match;
        let currQues: string = name.replaceAll('_', ' ');
        //Compare : Parent Question's Answer Type with Child Question's Answer Type
        let match_ans_err: string =
            is_re_type === 'yes'
                ? client_form[`Re-Type_${name}`] && value != client_form[`Re-Type_${name}`]
                    ? `${currQues} and Re-Type ${currQues} must match`
                    : ''
                : '';
        data.answer = value;
        // Validations:  Different type of Input Fields
       if (parent_question) {
            //Parent Question Validation
            let { client_form } = this.state;
            data.answer = value;
            let isEqualAnswer: boolean = client_form[parent_question] === value ? true : false;
            this.setState({
                error: {
                    ...this.state.error,
                    [name]: isEqualAnswer ? '' : error_msg
                },
                hasError: isEqualAnswer ? false : true
            });
        } else {
            // val1 = "Not Contains" (every time), val2 = ["Both", "Numbers", "Special Character]
            //# Validation: Based on defined validation at tool.
            let spl_characters = data.spl_characters;
            let val3 = data.validation3;   
            let val4 = data.validation4;  
            
                    const regex = Utility.regexHandler(val2, spl_characters, val3, val4, value, is_exact);
                    
                    const textRegex = new RegExp(regex); 
           this.setState({
                error: {
                    ...this.state.error,
                    [name]: textRegex.test(value) ? match_ans_err : error_msg ? error_msg : error_msg2
                },
                hasError: textRegex.test(value) ? false : true
            });
        }
        this.setState({
            DynamicQuestions,
            client_form: {
                ...this.state.client_form,
                [name]: value
            }
        });
    };

    numberHandler = async (e, data, idx, idy) => {
        const { name, value } = e.target;

        let DynamicQuestions = [...this.state.DynamicQuestions];
        let val1: any = data.validation1 ? data.validation1 : '';
        const val2: any = data.validation2 ? data.validation2 : '';
        const error_msg = data.error_msg;
        const err = parseInt(value) < parseInt(val1);
        const err1 = parseInt(value) > parseInt(val2);
        
        DynamicQuestions[idx].questions[idy].answer = value;
        this.setState((prevState) => ({
            ...prevState,
            DynamicQuestions,
            client_form: {
                ...prevState.client_form,
                [name]: value
            }
        }));

        this.setState((prevState) => ({
            ...prevState,
            DynamicQuestions,
            client_form: {
                ...prevState.client_form,
                [name]: value
            }
        }));

        this.setState((prevState) => ({
            ...prevState,
            error: {
                ...this.state.error,
                [name]: err ? error_msg : err1 ? error_msg : ''
            },
            hasError: err ? true : err1 ? true : false
        }));
    };

    MappedAnswersHandler = (id, idx, idy, data, configuredQuestions, isLoadTime) => {
        const mapped_answers_list = data.suggested_jump.find(
            (item) => item.answer?.toString() === data.answer?.toString()
        )?.mapping_answers;
        if (mapped_answers_list) {
            let updatedDynamicQuestions = [];
            mapped_answers_list?.map(
                (item) =>
                    item !== null &&
                    configuredQuestions.map((section) => {
                        updatedDynamicQuestions = {
                            ...section,
                            questions: section.questions.map((question) => {
                                const mappingItem = mapped_answers_list.find(
                                    (item) => item !== null && item.question === question.question
                                );

                                if (mappingItem && mappingItem !== null) {
                                    const updatedSuggestedAnswers = question.suggested_answers.map((answer) => {
                                        if (mappingItem.answers?.includes(answer.value)) {
                                            answer.is_deleted = false;
                                            if (!isLoadTime) {
                                                question.answer = '';
                                            }
                                        } else {
                                            answer.is_deleted = true;
                                            if (isLoadTime !== 'true') {
                                                question.answer = '';
                                            }
                                        }
                                        return answer;
                                    });
                                    return {
                                        ...question,
                                        suggested_answers: updatedSuggestedAnswers
                                    };
                                }
                                return question;
                            })
                        };
                    })
            );
            
            return updatedDynamicQuestions;
        }    
    };

    handleDatePicker = (idx, idy, date) => {
        let DynamicQuestions = [...this.state.DynamicQuestions];
        let error = '';
        let customDate = '';
        if (date) {
            var date2 = new Date(date),
                mnth = ('0' + (date2.getMonth() + 1)).slice(-2),
                day = ('0' + date2.getDate()).slice(-2);
            customDate = [date2.getFullYear(), mnth, day].join('/');
        } else {
            customDate = '';
        }

        DynamicQuestions[idx].questions[idy].answer = customDate;
        let isValidDate: any = date == 'Invalid Date' ? true : false;
        

        if (customDate !== 'NaN-aN-aN') {
            let maxStartDate: any = new Date('9998-12-31');
            if (DynamicQuestions[idx].questions[idy].question === 'Start Date') {
                let minStartDate: any = new Date(this.state.minStartDateValue);
               
               if(minStartDate > new Date(customDate.toString()) || new Date(customDate.toString()) > maxStartDate){
                isValidDate = true;
                error = `Start Date should be above ${ Utility.validateDateFormate(minStartDate)}.`;
               } else {
                isValidDate = false;
                error = '';
               }
            } else if (DynamicQuestions[idx].questions[idy].question === 'End Date') {
                let minStartDate: any = new Date(this.state.client_form['Start Date']);
                if(minStartDate > new Date(customDate.toString()) || new Date(customDate.toString()) > maxStartDate){
                            isValidDate = true;
                            error = `End Date should be above ${ Utility.validateDateFormate(minStartDate)}.`;
                           } else {
                            isValidDate = false;
                            error = '';
                           }
            } else if (DynamicQuestions[idx].questions[idy].question === 'Post-Program: YLS Date') {
                let minStartDate: any = new Date(this.state.client_form['End Date']);
                isValidDate =
                    minStartDate > new Date(customDate.toString()) || new Date(customDate.toString()) > maxStartDate
                        ? true
                        : false;
            } else {
                let minDefaultDate: any = this.state.client_form['Start Date']
                    ? new Date(this.state.client_form['Start Date'])
                    : new Date('1900-01-01');
                    if(minDefaultDate > new Date(customDate.toString()) || new Date(customDate.toString()) > maxStartDate){
                        isValidDate = true;
                        error = `Start Date should be above ${ Utility.validateDateFormate(minDefaultDate)}.`;
                       } else {
                        isValidDate = false;
                        error = '';
                       }
            }
        }

        this.setState((prevState) => ({
            ...prevState,
            DynamicQuestions,
            client_form: {
                ...prevState.client_form,
                [DynamicQuestions[idx].questions[idy].question]: customDate
            },
            isValid_date: {
                ...prevState.isValid_date,
                [DynamicQuestions[idx].questions[idy].question]: isValidDate
            },
            hasError: isValidDate,
            error: {
                ...prevState.error,
                [DynamicQuestions[idx].questions[idy].question]: error
            }
        }));
    };
    isAllDateValidated = () => {
        const isValid_date = { ...this.state.isValid_date };
        const client_form = this.state.client_form;
        if (client_form['Start Date']) {
            new Date(client_form['Start Date']) < new Date(this.state.minStartDateValue)
                ? (isValid_date['Start Date'] = true)
                : (isValid_date['Start Date'] = false);
        }
        if (client_form['End Date']) {
            new Date(client_form['End Date']) < new Date(client_form['Start Date'])
                ? (isValid_date['End Date'] = true)
                : (isValid_date['End Date'] = false);
        }
        if (client_form['Post-Program: YLS Date']) {
            new Date(client_form['Post-Program: YLS Date']) < new Date(client_form['End Date'])
                ? (isValid_date['Post-Program: YLS Date'] = true)
                : (isValid_date['Post-Program: YLS Date'] = false);
        }
        return isValid_date;
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const client_form = this.state.client_form;
        let Required_List = this.state.Required_List;
        let isValid_date = this.isAllDateValidated();
        this.setState({
            isSubmitted: true,
            err_msg: [],
            isOpen: false
        });
        let data = [] as any;
        let isValid_Data = true as any;
        Object.keys(client_form).map(
            (ele, i) => (
                data.push({ [ele]: client_form[ele] }),
                !client_form[ele] && Required_List[ele] === 'yes' && (isValid_Data = false)
            )
        );

        let formData = Object.assign({}, ...data, {
            client_code: this.props.index,
            version: this.state.currentVersion,
            Comments: this.state.comments,
            _id: this.state.modifyOutcome
        });

        const everyDateQuestion = Object.values(isValid_date).every((value) => value === false);
        
        if (isValid_Data && everyDateQuestion) {
            // if (!this.state.hasError) {
            let searchData = [...this.props.searchData];
            let findSelectedVersion = searchData.filter(
                (versions) => versions.version === this.state.currentVersion && versions
            );
            let modifyData = !PendingReferralList.includes(
                this.state.DynamicQuestions[0]?.questions[0]?.answer?.toLowerCase()
            );

            this.setState({
                isSubmitted: false,
                err_msg: this.props.errors,
                isOpen: this.props.errors ? true : false,
                // isSuccess: true,
                selectedVersion: findSelectedVersion,
                modifyOutcome: '',
                isModifyData: modifyData
            });
            await this.props.onFormSubmit(formData);
            //window.location.reload();
            //}
        } else {
            this.setState({
                isValid_date: isValid_date
            });
        }
    };

    versionChange = async (e) => {
        const { value } = e.target;
        var optionElement = e.target.childNodes[e.target.selectedIndex];
        let version_id = optionElement.getAttribute('data-id');
        let record = JSON.parse(optionElement.getAttribute('data-record'));
        let version_changed = [...PendingReferralList, '', undefined, null].includes(
            version_id?.toString()?.toLowerCase()
        )
            ? false
            : true;

        let searchData = [...this.props.searchData];
        // Filter - Specific Version
        const Version = searchData?.filter((sec, i) => sec.version === value && sec);
        // Find - ODM Parent Question
        const odmQuestion = this.findOdmParentQuestion(searchData, Version[0].version);
        let DynamicQuestions =
            Version[0] && Version[0].sections.find((section) => section.section === 'Outcomes' && section);
        // Update - Outcome ODM Question Related value
        let newDynamicQuestions = this.updatedOutcomesSection([DynamicQuestions], odmQuestion);

        // Outcome question
        DynamicQuestions = newDynamicQuestions[0];

        //#Find and calculate :  The minimum Start Date value (preProgramYLSDate or dateOfReferral):  among both which ever is greater
        let preProgramYLSDate = '',
            dateOfReferral = '';
        Version[0] &&
            Version[0].sections.map(
                (section) =>
                    section.questions &&
                    section.questions.length > 0 &&
                    section.questions.filter((question) => {
                        if (question.question === 'Pre-Program: YLS Date') {
                            preProgramYLSDate = question.answer;
                        }
                        if (question.question === 'Date of Referral') {
                            dateOfReferral = question.answer;
                        }
                    })
            );
        let minStartDateValue: any = preProgramYLSDate > dateOfReferral ? preProgramYLSDate : dateOfReferral;
        //#Find current question jump value name
        const currQuesJump =
            DynamicQuestions &&
            DynamicQuestions.questions &&
            DynamicQuestions.questions
                .map(
                    (ques) =>
                        ques.question && 
                        ques.suggested_jump.length > 0 &&
                        ques.suggested_jump.find(
                            (ans, i) =>
                                ans.answer === ques.answer ||
                                ans.answer === ques.answer?.toString() ||
                                i.toString() === ques.answer
                        )?.question_jumpto 
                )
                .filter((item) => item !== false)
                ?.flat(); 
                //1. Collecting
        let jumpedQuestion: any = {};
//1. Collecting

        currQuesJump && currQuesJump.length > 0 && currQuesJump.map((ques) => (jumpedQuestion[ques] = ''));

        //2. Collecting client_form : if questions have answer
        let isAnswer: any = {};
        DynamicQuestions &&
            DynamicQuestions.questions.map((ques) => {
                if (ques.answer || (Array.isArray(ques.answer) && ques.answer.length > 0)) {
                    return (isAnswer[ques.question] = '');
                } else if(ques.question &&  ques.related !== 'yes') {
                    return (isAnswer[ques.question] = '');
                }
            });

        let client_form_data_one: any = { ...jumpedQuestion, ...isAnswer };

        //client_form mapped for selected version
        const client_form_mapped = (answer: any, answer_type: any, question: any, suggested_answers: any) => {
            let data: any = [];
            Array.isArray(answer)
                ? answer.filter((o1, i) => {
                      answer_type === 'CHECKBOX'
                          ? suggested_answers.some((o2, j) => {
                                if (o1 === o2?.value) {
                                    data.push(o2.id.toString());
                                }
                                client_form_data_one[question] = data;
                            })
                          : suggested_answers.some((o2, j) => {
                                if (o1 === o2?.value) {
                                    client_form_data_one[question] = [o2.id.toString()];
                                }
                            });
                  })
                : ['DATE', 'TEXT', 'NUMBER'].includes(answer_type)
                ? (client_form_data_one[question] = answer)
                : suggested_answers.some((o2) => {
                      if (answer === o2?.value) {
                          client_form_data_one[question] = o2.id.toString();
                      }
                  });
        };

        //Updating client_form for selected Version
        let DynamicQuestionsConfigure: any = { ...DynamicQuestions };
        DynamicQuestionsConfigure &&
            DynamicQuestionsConfigure.questions.map((ques) =>
                Object.keys(client_form_data_one).filter((ele) => {
                    if (ele === ques.question) {
                        ques.related = 'no';
                        client_form_mapped(ques.answer, ques.answer_type, ques.question, ques.suggested_answers);
                    }
                })
            );
            
        //For last version : pushing history version undefined
        let id_n: string = Version[0][this.props.uniqueIdField];
        let version: number = parseInt(Version[0].version.substring(1)) - 1;
        let modifyData = false;
        if (
            [...PendingReferralList, '', undefined].includes(version_id?.toLowerCase()) ||
            value === searchData[searchData.length - 1].version
        ) {
            this.props.isLastVersion(id_n, version);
        }
        const latestVersion = searchData[searchData.length - 1];

        if (latestVersion?.version !== Version[0]?.version) {
            modifyData = false;
        } else {
            if (![...PendingReferralList, '', undefined].includes(version_id?.toLowerCase())) {
                modifyData = true;
            }
        }

        this.setState({
            DynamicQuestions: DynamicQuestionsConfigure ? [DynamicQuestionsConfigure] : [],
            selectedVersion: Version && Version,
            client_form: client_form_data_one,
            minStartDateValue: minStartDateValue,
            currentVersion: Version[0] && Version[0].version,
            version_changed: version_changed,
            version_color: record?.version_color,
            isModifyData: modifyData
        });
        //this.props.onVersionSelect(this.props.searchData[0].id_n, version_id);
    };
    //To group nested question which are related
    groupingNestingQuestions = (id) => {
        let questionsList = this.state.selectedVersion && [...this.state.selectedVersion[0].sections[id].questions];
        let filteredQuestions: any = [];
        let relatedJumptoQuestions: any = [];
        let unrelatedJumpToQuestions: any = [];
        let relatedJumptoQuestionsForArrayType: any = [];
        let unrelatedJumpToQuestionsForArrayType: any = [];
        for (let question = 0; question < questionsList.length; question++) {
            if (questionsList[question]?.answer_type === 'SELECT' || questionsList[question]?.answer_type === 'RADIO') {
                if (questionsList[question]?.suggested_jump?.length > 0) {
                    //To check whether the answer type is Array
                    if (Array.isArray(questionsList[question]?.answer)) {
                        const relatedSuggestedJump = questionsList[question]?.suggested_jump?.filter((item) =>
                            item.answer?.includes(questionsList[question].answer)
                        );
                        const unrelatedSuggestedJump = questionsList[question]?.suggested_jump?.filter(
                            (item) => !item.answer?.includes(questionsList[question].answer)
                        );
                        if (relatedSuggestedJump.length > 0) {
                            relatedSuggestedJump.forEach((obj) => {
                                obj.question_jumpto.forEach((ques) => {
                                    relatedJumptoQuestionsForArrayType.push(ques);
                                });
                            });
                        }
                        if (unrelatedSuggestedJump.length > 0) {
                            unrelatedSuggestedJump.forEach((obj) => {
                                obj.question_jumpto.forEach((ques) => {
                                    unrelatedJumpToQuestionsForArrayType.push(ques);
                                });
                            });
                        }
                    } else {
                        const relatedSuggestedJump = questionsList[question]?.suggested_jump?.filter(
                            (item) => item.answer === questionsList[question].answer
                        );
                        const unrelatedSuggestedJump = questionsList[question]?.suggested_jump?.filter(
                            (item) => item.answer !== questionsList[question].answer
                        );
                        if (relatedSuggestedJump.length > 0) {
                            relatedSuggestedJump.forEach((obj) => {
                                obj.question_jumpto.forEach((ques) => {
                                    relatedJumptoQuestions.push(ques);
                                });
                            });
                        }
                        if (unrelatedSuggestedJump.length > 0) {
                            unrelatedSuggestedJump.forEach((obj) => {
                                obj.question_jumpto.forEach((ques) => {
                                    unrelatedJumpToQuestions.push(ques);
                                });
                            });
                        }
                    }
                }
            }
        }
        const allRelated = [...relatedJumptoQuestions, ...relatedJumptoQuestionsForArrayType];
        const allunRelated = [...unrelatedJumpToQuestions, ...unrelatedJumpToQuestionsForArrayType];
        const mainUnRelatedQuestions = allunRelated.filter((element) => !allRelated.includes(element));
        for (let que = 0; que < questionsList.length; que++) {
            const unrelated = mainUnRelatedQuestions?.includes(questionsList[que]?.question);

            if (unrelated === false) {
                filteredQuestions.push(questionsList[que]);
            }
        }

        return filteredQuestions;
    };

    display = (id, ver) => {
        const tempArray = [] as any;
        let questionsList = this.groupingNestingQuestions(id);

        const length: any = questionsList && questionsList?.length;
        for (let i = 0; i < length; i++) {
            if ((i + 1) % 2 !== 0) {
                var tempArray1 = [] as any;
                tempArray1.push(questionsList[i]);
                if (length === i + 1) {
                    tempArray.push(tempArray1);
                }
            } else {
                tempArray1.push(questionsList[i]);
                tempArray.push(tempArray1);
            }
        }

        return tempArray;
    };

    is_date = function (date) {
        let dateObj: any = new Date(date.replace(/-/g, '/'));
        const regex = new RegExp(
            '([0-9]{4}[-](0[1-9]|1[0-2])[-]([0-2]{1}[0-9]{1}|3[0-1]{1})|([0-2]{1}[0-9]{1}|3[0-1]{1})[-](0[1-9]|1[0-2])[-][0-9]{4})'
        );
        if (regex.test(date)) {
            let year = dateObj.getFullYear();
            let month = 1 + dateObj.getMonth();
            let datee = dateObj.getDate();
            let date1 = [month.toString().length > 1 ? month : `0${month}`, datee > 9 ? datee : `0${datee}`, year].join(
                '/'
            );
            return date1;
        }
        return date;
    };
    validateDateFormate = (str) => {
        var date = new Date(str),
            mnth = ('0' + (date.getMonth() + 1)).slice(-2),
            day = ('0' + date.getDate()).slice(-2);
        return [mnth, day, date.getFullYear()].join('/');
    };

    checkboxAnsDisable = (client_form: any, ques: any, ans: any) => {
        if (client_form[ques.question] && client_form[ques.question][0]) {
            let findEqualSet =
                ans.setType ===
                ques.suggested_answers.find((answer) => answer.id === Number(client_form[ques.question][0]))?.setType;
            return findEqualSet ? false : true;
        } else {
            return false;
        }
    };

    rerefferHandler = () => {
        this.setState({
            isModalOpen: true
        });
    };

    closeModal = () => {
        this.setState({
            isModalOpen: false
        });
    };

    continueHandler = () => {
        this.setState({
            isModalOpen: false
        });
        this.props.RedirectForReRefer();
        // this.history = new useHistory();
        //this.props.history.push(`/${domainPath}/existing-client/edit-details/${this.props.index}&true&true`)
    };
   

    handleTransferPrediction = (data, secId, quesId) => {
        
        let referral_answer = this.state.DynamicQuestions[0].questions[quesId].answer;

        let result = data.questions.some( 
            (item) => {
                if((referral_answer === 1 || referral_answer === "Placed") && item.question === 'Program Completion'){
                     return item.answer === null || item.answer === ''; 
                } 
            }
        );

        return ['adelphoi-dynamic', 'adelphoi'].includes(domainPath) ? result : false;
    };

    render() {
        const {
            DynamicQuestions,
            header_color,
            isSubmitted,
            isModifyData,
            Required_List,
            client_form,
            selectedVersion,
            currentVersion,
            modifyOutcome,
            version_changed
        } = this.state;
        const { searchData, org_type, configType, subConfigType } = this.props;
        const searchIndex = searchData && searchData.length - 1;
        const selectedIndex = selectedVersion && selectedVersion.length - 1;
        const latestVersion = searchData[searchData.length - 1];
        let clientCode = this.props.index; 
        const is_accessToken: string | null = (this.props.is_accessToken && this.props.is_accessToken) || null;
        let disableField = [1, 3, 4].includes(org_type)
            ? modifyOutcome
                ? false
                : version_changed
                ? true
                : isModifyData
                ? latestVersion?.version === selectedVersion.version
                    ? false
                    : true
                : isModifyData
            : false;
            
        return (
            <div css={wrap}>
                <div css={mainContent}>
                    <Backdrop css={backdrop} open={this.props.isLoading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    <div css={fieldRow}>
                        <div css={twoCol}>
                            {org_type !== 2 && ['Super Admin', 'Tester'].includes(this.props.is_role_type) ? (
                                <Button
                                    type="submit"
                                    size="medium"
                                    variant="contained"
                                    style={{
                                        marginRight: 10,
                                        backgroundColor: header_color,
                                        color: '#fff',
                                        textTransform: 'capitalize'
                                    }}
                                    css={txtDetail}
                                    onClick={() => this.props.downloadModifyHardSave(selectedVersion[0]?._id)}
                                >
                                    <PictureAsPdfIcon />
                                    Download Updated History
                                </Button>
                            ) : (
                                <Fragment>&nbsp;</Fragment>
                            )}
                        </div>
                        <div css={twoCol}>
                            <div css={txtDetail}>
                                <select css={selectField} name="versions" onChange={this.versionChange}>
                                    <option value={searchData[searchData.length - 1]?.version}>Select Version</option>
                                    {searchData.map((data, i) => (
                                        <option
                                            key={i}
                                            data-id={
                                                searchData.length - 1 === i
                                                    ? searchData[searchIndex].sections
                                                          .find((sec) => sec.section === 'Outcomes' && sec)
                                                          ?.questions.find((ques) =>
                                                              ques.question === 'Referral Status' &&
                                                              Array.isArray(ques.answer)
                                                                  ? ques.answer[0]
                                                                  : ques.answer
                                                          )?.answer
                                                    : i
                                            }
                                            value={data.version}
                                            data-record={JSON.stringify(data)}
                                            selected={data.version === searchData[this.props.versionIndex]?.version}
                                            style={{ color: data.version_color === 'normal' ? '' : 'red' }}
                                        >
                                            {data.display_version}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        {!this.props.is_kid_report_configured ? (
                            ''
                        ) : (
                            <div
                                css={twoCol}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    textAlign: 'center',
                                    paddingTop: 13,
                                    paddingBottom: 20
                                }}
                            >
                                <label
                                    css={reportLink}
                                    onClick={() => this.props.downloadKidReport(clientCode)}
                                    
                                >
                                    <PictureAsPdfIcon /> Download Report
                                </label>
                            </div>
                        )}
                    </div>
                    <SubHeading label="Client" 
                                subHead={"Existing"} 
                                configType={this.props.configType} 
                                subConfig={this.props.subConfigType} 
                                refferalInfo={this.props.refferalInfo}/>
                    {selectedVersion &&
                        selectedVersion[selectedIndex]?.sections?.map(
                            (ques, id) =>
                                ques.related === 'false' &&
                                ques.section !== 'Outcomes' && (
                                    <Accordion defaultExpanded={id === 0 ? true : false} key={id}>
                                        <AccordionSummary
                                            css={panelHeader}
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                        >
                                            <h1 css={panelHeading}>{ques.section}</h1>
                                        </AccordionSummary>
                                        <AccordionDetails css={panel}>
                                            {this.display(id, selectedVersion).map((item, index) => {
                                                return (
                                                    <React.Fragment>
                                                        <div css={fieldRow} key={index}>
                                                            {item.map((ques, index_1) =>
                                                                ques.is_child !== 'yes' && ques.is_parent !== 'yes' ? (
                                                                    <div css={twoCol} key={index_1}>
                                                                        <label css={txtLabel}>{ques.question}</label>
                                                                        <div css={txtDetail}>
                                                                            {typeof ques.answer === 'object' &&
                                                                            !Array.isArray(ques.answer)
                                                                                ? ques.answer.value
                                                                                : Array.isArray(ques.answer)
                                                                                ? ques.answer?.toString()
                                                                                : ques.answer?.toString().includes('-')
                                                                                ? this.is_date(ques.answer)
                                                                                : ques.question === 'Age'
                                                                                ? this.props.DynamicQuestions[0]
                                                                                      ?.ageAtEpisodeStart
                                                                                    ? this.props.DynamicQuestions[0]
                                                                                          .ageAtEpisodeStart
                                                                                    : ques.answer
                                                                                : ques.answer}
                                                                            {ques.is_parent === 'yes'}
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    ques.is_child !== 'yes' && (
                                                                        <DisplayDynamicQuestions
                                                                            data={ques}
                                                                            index={index_1}
                                                                            is_date={this.is_date}
                                                                            sections={selectedVersion[selectedIndex]?.sections}
                                                                        />
                                                                    )
                                                                )
                                                            )}
                                                        </div>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </AccordionDetails>
                                    </Accordion>
                                )
                        )}
                    <div css={flexBetween}>
                        {this.props.searchData &&
                            this.props.searchData.length > 0 &&
                            this.props.searchData.map((searchData: any, index: number) => {
                                if (searchData.version === currentVersion) {
                                    return searchData?.sections.map((questions: any, id: number) => (
                                        <React.Fragment>
                                            {questions.section === 'Outcomes' &&
                                                questions?.questions.map((ques, ind) => {
                                                    return (
                                                        <React.Fragment>
                                                            {ques?.question === 'Referral Status' && (
                                                                <React.Fragment>
                                                                    {!isModifyData ? (
                                                                        index === searchIndex && (
                                                                            <h3>
                                                                                Click here to{' '}
                                                                                <Link
                                                                                 to={{
                                                                                        pathname: `/${domainPath}/existing-client/edit-details/config=${configType}&subConfig=${subConfigType}&index=${this.props.index}&isEdit=true&Rerefer=false`,
                                                                                        state: {
                                                                                            isPredictionScores: false,
                                                                                            version: currentVersion
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <u style={{ color: 'red', cursor: "pointer" }}>
                                                                                        Update
                                                                                    </u>
                                                                                </Link>{' '}
                                                                                the Client details.
                                                                            </h3>
                                                                        )
                                                                    ) : ([1,2,3,4].includes(org_type) && index === searchIndex) &&
                                                                      this.handleTransferPrediction(questions, id, ind) ? (
                                                                        <h3>
                                                                            <Link
                                                                                to={{
                                                                                    pathname: `/${domainPath}/existing-client/edit-details/config=${configType}&subConfig=${subConfigType}&index=${this.props.index}&isEdit=true&Rerefer=false`,
                                                                                    state: {
                                                                                        isPredictionScores: true,
                                                                                        version: currentVersion
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <u style={{ color: 'red' }}>
                                                                                    Get Transfer Prediction
                                                                                </u>
                                                                            </Link>{' '}
                                                                        </h3>
                                                                    ) : (
                                                                        <h3>
                                                                            {' '}
                                                                            Click here to{' '}
                                                                            <u
                                                                                style={{
                                                                                    color: 'red',
                                                                                    cursor: 'pointer'
                                                                                }}
                                                                                onClick={() =>
                                                                                    [1, 3].includes(org_type)
                                                                                        ? this.rerefferHandler()
                                                                                        : this.props.RedirectForReRefer()
                                                                                }
                                                                            >
                                                                                Re-Refer
                                                                            </u>{' '}
                                                                            the Client.
                                                                        </h3>
                                                                    )}
                                                                </React.Fragment>
                                                            )}
                                                        </React.Fragment>
                                                    );
                                                })}
                                        </React.Fragment>
                                    ));
                                }
                            })}
                        {org_type !== 2 ? (
                            // [...PendingReferralList, '']?.includes(
                            //     this.props.DynamicQuestions[0]?.questions[0]?.answer?.toLowerCase()
                            // ) &&
                            !isModifyData ? (
                                ''
                            ) : (
                                <h3>
                                    <ModifyHardSaveData
                                        client={this.props.index}
                                        is_role_type={this.props.is_role_type}
                                        SelectedVersion={selectedVersion[0]}
                                        modifyOutcome={modifyOutcome}
                                        setModifyOutcome={this.setModifyOutcome}
                                        orgType={org_type}
                                        latestVersion={latestVersion}
                                        configType={configType}
                                        subConfigType={subConfigType}
                                    />
                                </h3>
                            )
                        ) : (
                            ''
                        )}
                    </div>
                    <form
                        name="newClientForm"
                        onChange={this.handleChange}
                        onSubmit={this.handleSubmit}
                        style={{ marginTop: 20 }}
                    >
                        {DynamicQuestions.map((sections, index) =>
                            sections.related === 'true' ? (
                                ''
                            ) : (
                                <React.Fragment>
                                    {sections.questions?.map((ques, ind) => {
                                        return (
                                            <div key={ind} css={fieldRow} style={{ marginBottom: 0 }}>
                                                {ques.related !== 'yes' && (
                                                    <React.Fragment>
                                                        <div key={ind} css={twoCol}>
                                                            <label css={label}>{ques.question}</label>
                                                            {ques.description && (
                                                                <label>
                                                                    <small>({ques.description})</small>
                                                                </label>
                                                            )}{' '}
                                                        </div>
                                                        <br />
                                                        <div key={ind + 'option'} css={twoCol}>
                                                            {ques.answer_type === 'SELECT' ? (
                                                                <React.Fragment>
                                                                    <Dropdown
                                                                        data={ques}
                                                                        sectionIndex={index}
                                                                        questionIndex={ind}
                                                                        state={this.state}
                                                                        handleChange={this.dropDownHandler}
                                                                        disableField={disableField}
                                                                        updateLocalState={()=>{}}
                                                                    />
                                                                </React.Fragment>
                                                            ) : ques.answer_type === 'RADIO' ? (
                                                                <React.Fragment>
                                                                    <Radio
                                                                        data={ques}
                                                                        sectionIndex={index}
                                                                        questionIndex={ind}
                                                                        state={this.state}
                                                                        handleChange={this.dropDownHandler}
                                                                        updateLocalState={()=>{}}
                                                                        disableField={disableField}
                                                                    />
                                                                </React.Fragment>
                                                            ) : ques.answer_type === 'CHECKBOX' ? (
                                                                <Fragment>
                                                                    <Checkbox
                                                                        data={ques}
                                                                        sectionIndex={index}
                                                                        questionIndex={ind}
                                                                        state={this.state}
                                                                        handleChange={this.checkBoxHandler}
                                                                        updateLocalState={() => {}}
                                                                        disableField={disableField}
                                                                    />
                                                                </Fragment>
                                                            ) : ques.answer_type === 'TEXT' ? (
                                                                <Fragment>
                                                                    <InputField
                                                                        data={ques}
                                                                        sectionIndex={index}
                                                                        questionIndex={ind}
                                                                        state={this.state}
                                                                        preventCopyPaste={Utility.preventCopyPaste}
                                                                        handleChange={this.textFieldHandler}
                                                                        updateLocalState={() => {}}
                                                                        keyUp={() => {}}
                                                                        disableField={disableField}
                                                                        configType={configType}
                                                                        subConfigType={subConfigType}
                                                                    />
                                                                </Fragment>
                                                            ) : ques.answer_type === 'DATE' ? (
                                                                <DateField
                                                                    data={ques}
                                                                    sectionIndex={index}
                                                                    questionIndex={ind}
                                                                    state={this.state}
                                                                    validateDateFormate={
                                                                        Utility.validateDateFormate
                                                                    }
                                                                    handleDatePicker={this.handleDatePicker}
                                                                    updateLocalState={() => {}}
                                                                    DynamicQuestions={this.state.DynamicQuestions}
                                                                    disableField={disableField}
                                                                    configType={configType}
                                                                    subConfigType={subConfigType}
                                                                />
                                                            ) : (
                                                                <Fragment>
                                                                    <NumberField
                                                                        data={ques}
                                                                        sectionIndex={index}
                                                                        questionIndex={ind}
                                                                        state={this.state}
                                                                        getAge={Utility.getAge}
                                                                        isPredictionScores={
                                                                            this.props.isPredictionScores
                                                                        }
                                                                        handleChange={this.numberHandler}
                                                                        updateLocalState={() => {}}
                                                                        keyUp={() => {}}
                                                                        disableField={disableField}
                                                                        configType={configType}
                                                                        subConfigType={subConfigType}
                                                                    />
                                                                </Fragment>
                                                            )}

                                                            {this.state.error[ques.question] && (
                                                                <div style={{ color: 'red' }}>
                                                                    {this.state.error[ques.question]}
                                                                </div>
                                                            )}
                                                            {isSubmitted &&
                                                            Required_List[ques.question] &&
                                                            Required_List[ques.question] === 'yes' &&
                                                            ques.answer === '' ? (
                                                                <div style={{ color: 'red' }}>Required</div>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        );
                                    })}
                                    {modifyOutcome && (
                                        <div css={fieldRow}>
                                            <div css={textArea_50}>
                                                <label css={dangerLabel}>Reason for modification</label>
                                            </div>
                                            <div css={textArea_50}>
                                                <textarea
                                                    css={textArea}
                                                    id="comments"
                                                    name="comments"
                                                    value={this.state.comments || ''}
                                                    onChange={(e) => this.setState({ comments: e.target.value })}
                                                    rows={6}
                                                    placeholder=""
                                                    required={true}
                                                ></textarea>
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            )
                        )}
                        <div css={fieldRow} style={{ justifyContent: 'flex-end' }}>
                            <Button
                                type="submit"
                                variant="contained"
                                size="large"
                                color="primary"
                                disabled={this.props.isLoading || disableField}
                            >
                                Submit
                            </Button>
                        </div>
                    </form>
                </div>
                {/* MAIN CONTENT */}
                <Modal
                    isOpen={this.state.isModalOpen}
                    ariaHideApp={false}
                    onRequestClose={() => this.closeModal()}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <h3>Alert...​</h3>
                    <div>
                        Prior to re-referring, please confirm the Outcomes Section for the current referral is
                        completed. Click “modify outcome” to open fields for data input. Previous version details will
                        become locked and non-editable after a re-referral is performed.
                    </div>
                    <br />
                    <div css={fieldRow}>
                        <div style={{ justifyContent: 'flex-start' }}>
                            <Button
                                type="submit"
                                size="large"
                                variant="contained"
                                color="primary"
                                onClick={() => this.continueHandler()}
                            >
                                Continue
                            </Button>
                        </div>
                        <div style={{ justifyContent: 'right' }} onClick={() => this.closeModal()}>
                            <Button type="submit" size="large" variant="contained" color="primary">
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}
export default withRouter(DynamicClientDetails);