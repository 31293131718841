/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { subHeading, fieldRow } from './styles';
import { domainPath } from '../App';
import { fetchNotificationsList } from '../api/api';

export interface notifications {
    url: string;
    id: number;
    customer: number;
    name: string;
    is_published: string;
}

export interface DynamicNotificationProps {
    accessToken: string;
    headerColor: string;
    Config: string;
    subConfig: string;
    tabLabel: string;
}

const DynamicNotifications: React.FC<any> = (props: DynamicNotificationProps) => {
    const [notificationsList, setNotificationsList] = useState<notifications[]>();
    const history = useHistory();

    // useEffect(() => {
    //     (async () => {
    //         try {
    //             const response: any = await fetchNotificationsList(props.accessToken);
    //             setNotificationsList(response);
    //         } catch (e) {
    //             alert('Unable to fetch notifications list');
    //         }
    //     })();

    //     return () => {
    //         // this now gets called when the component unmounts
    //     };
    // }, []);
    
    useEffect(() => {
        (async () => {
            try {
                const response: any = await fetchNotificationsList(props.accessToken);
                setNotificationsList(response);
            } catch (e) {
                alert('Unable to fetch notifications list');
            }
        })();

        return () => {
            // this now gets called when the component unmounts
        };
    }, [props.subConfig]);

    return (
        <form name="UsersForm">
            <h1 css={subHeading} style={{ color: props.headerColor }}>
              {props.tabLabel} {" "} Notification Center:
            </h1>
            {notificationsList && notificationsList.length > 0
                ? notificationsList?.map((item, index) => {
                      return (
                          <div css={fieldRow} key={index}>
                              <Link
                                  to={`/${domainPath}/notifications/details/config=${props.Config}&subConfig=${props.subConfig}&index=${item.id}`}
                                  onClick={() => {
                                      history.push(`/${domainPath}/notifications/details/config=${props.Config}&subConfig=${props.subConfig}&index=${item.id}`);
                                  }}
                              >
                                  {`${item.name} - Click to View`}
                              </Link>
                          </div>
                      );
                  })
                : 'Notifications yet to be configured.'}
        </form>
    );
};

export default DynamicNotifications;