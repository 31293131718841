/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, {memo} from 'react';
import SubHeadingDetails from './SubHeading.config.json';

const SubHeading = (props) => {
    let { label, subHead, configType, subConfig, refferalInfo } = props;
    refferalInfo = refferalInfo ? refferalInfo : [];

let tabLabel = refferalInfo?.find((item) => item.config_type?.toString() === configType?.toString())?.referral_forms.find((list) => list.sub_config_type?.toString() === subConfig?.toString())?.label;
let messgae = Object.entries(SubHeadingDetails).map(([key, value]) => {
    
    if(subHead === key) {
        return SubHeadingDetails[key].message1;
    }
});
    return (
        <div className="container-fluid mb-5">
          <h2>{messgae} {" "} {tabLabel}: </h2>
        </div>
    );
};

export default SubHeading;
