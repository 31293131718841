import * as React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../redux-modules/root';
import { ContainerProps } from './Container';
import { Switch, Route } from 'react-router-dom';
import { WithSnackbarProps } from 'notistack';
import NotificationsFetchDetails from '../components/NotificationsFetchDetails';
import { domainPath } from '../App';
import DynamicNotifications from '../components/DynamicNotifications';
import DynamicNotificationDetails from '../components/DynamicNotificationDetails';
import { KeyUpService } from '../Services/KeyUpService';
interface MatchParams {
    index: string;
}

export interface NotificationsContainerState {
    isLoading: boolean;
    error: string;
    headerColor: string;
    org_type: any;
    is_Searched: boolean;
}
export interface NotificationsContainerProps extends ContainerProps<MatchParams>, WithSnackbarProps {
    null
}

export class NotificationsContainer extends React.Component<NotificationsContainerProps, NotificationsContainerState> {
    constructor(props: NotificationsContainerProps) {
        super(props);
        this.state = {
            isLoading: false,
            error: '',
            headerColor: '',
            org_type: null,
            is_Searched: false
        };
    }

    async componentDidMount() {
        const header_color: any = this.props.user && this.props.user.user.header_color;
        const org_type: any = this.props.user && this.props.user.user.org_type;
        this.setState({
            headerColor: header_color,
            org_type: org_type
        });
    }
    
    isDataAvailable = () => {
        this.setState({
            is_Searched: false
        });
    };

    render() {
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
        const { client: clientState } = this.props;
        const clientList = (clientState && clientState.clientList) || {};
        const { is_Searched } = this.state;
        const { Config, subConfig } = KeyUpService.getConfSubConf(this.props.location.pathname);
        const refferalInfo: any = this.props.user && this.props.user.user.referral_info;
        let tabLabel = refferalInfo.find((item) => item.config_type?.toString() === Config?.toString())?.referral_forms.find((list) => list.sub_config_type?.toString() === subConfig?.toString())?.label;
        return (
            <Switch>
                <Route exact path={`/${domainPath}/notifications/fetching-client/client-details/config=:config&subConfig=:subConfig&index=:index`}>
                    <NotificationsFetchDetails
                        {...this.state}
                        clientList={is_Searched ? Object.values(clientList) : []}
                        clearData={this.isDataAvailable}
                    />
                </Route>
                <Route exact path={`/${domainPath}/notifications/config=:config&subConfig=:subConfig`}>
                    <DynamicNotifications
                        accessToken={is_accessToken}
                        header_color={this.state.headerColor}
                        Config={Config}
                        subConfig={subConfig}
                        tabLabel={tabLabel}
                    />
                </Route>
                <Route exact path={`/${domainPath}/notifications/details/config=:config&subConfig=:subConfig&index=:id`}>
                    <DynamicNotificationDetails 
                    accessToken={is_accessToken} 
                    headerColor={this.state.headerColor}
                    Config={Config}
                    subConfig={subConfig}
                    tabLabel={tabLabel}
                        />
                </Route>
            </Switch>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        client: state.client,
        user: state.user,
        notifications: state.notifications
    };
};

export default connect(mapStateToProps, null)(NotificationsContainer);